<template>
  <v-form @submit.prevent="login(username, password)">
    {{display}}
    <v-container>
      <v-col md="4" offset-md="4">

        <h1>Login</h1>

        <v-row v-if="error">
          {{error}}
        </v-row>
        <v-row>
          <v-text-field label="Username" v-model="username" autocomplete="username" required>

          </v-text-field>
        </v-row>
        <v-row>
          <v-text-field label="Password" type="password" v-model="password" autocomplete="current-password" required>

          </v-text-field>
        </v-row>
        <v-row>
          <v-btn color="success" type="submit">
            Login!
          </v-btn>
        </v-row>
      </v-col>
    </v-container>
  </v-form>
</template>

<script>

let baseURL = ''
if (process.env.NODE_ENV === 'production'){
  baseURL = new URL(window.location).origin
}else{
  baseURL = 'http://localhost:8080'
}

export default {
  name: "Login",
  props: ['display'],
  data: function () {
    return {
      "username": "",
      "password": "",
      "error": ""
    }
  },
  methods: {
    login: function(username, password){
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({user: username, password:password})
      };
      fetch(baseURL+"/login", requestOptions)
        .then(response => {
          if (response.ok){
            return response.json().then(data => {
              this.$store.dispatch('loginUser', {
                "user": username,
                "tokenHash": data['tokenHash'],
                "simbriefUser": data['simbriefUser']
              })
              this.$router.push({name: 'Home'})
            })
          }
          return null
        })
    }
  }
}
</script>

<style scoped>

</style>
